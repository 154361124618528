export const TestIDs = {
  MintAction: {
    Wrapper: 'mint-action-wrapper',
    MintBtn: 'mint-btn',
    TermsCheckbox: 'terms-and-conditions-checkbox'
  },
  MintEligibility: {
    EligibilityInfoCta: 'eligibility-info-cta',
    EligibleText: 'eligible-text',
    NotEligibleContainer: 'not-eligible-container',
  },
  WalletStatusDropdown: {
    Wrapper: 'wallet-status-dropdown-wrapper',
    ConnectionText: 'wallet-status-dropdown-connection-text',
    ConnectedWithText: 'wallet-status-dropdown-connected-with-text',
    ChangeWalletBtn: 'wallet-status-dropdown-change-wallet-btn',
    DisconnectWalletBtn: 'wallet-status-dropdown-disconnect-wallet-btn',
  },
  RemainingCounter: {
    TotalDoneText: 'remaining-counter-total-minted-text',
    TotalRemainingText: 'remaining-counter-total-left-text',
  },
  NftsPerTxSelector: {
    Wrapper: 'nfts-per-tx-selector-wrapper',
    AddBtn: 'nfts-per-tx-selector-add-btn',
    RemoveBtn: 'nfts-per-tx-selector-remove-btn',
    ToMintCountInputField: 'nfts-per-tx-selector-to-mint-input-field',
  },
  MintPhase: {
    Wrapper: 'mint-phase-wrapper'
  },
  View: {
    MintMain: 'mint-main-view',
    MintSuccess: 'mint-success-view',
    RevealMain: 'reveal-main-view',
  },
  RevealFunctionality: {
    MoreInfoWrapper: 'reveal-more-info-wrapper'
  }
}