import { useEffect, useState } from "react";
import { StyledGradientText, StyledLinearProgress } from "./styles";

export interface RevealTimerProps {
  revealAt: number,
  onFinished: () => void
}

export const RevealTimer: React.FC<RevealTimerProps> = ({
  revealAt,
  onFinished
}) => {
  const [minutesLeft, setMinutesLeft] = useState(0)
  const [secondsLeft, setSecondsLeft] = useState(0)
  const [progress, setProgress] = useState(0)

  const NUMBER_OF_MINUTES_TO_REVEAL = 30

  const getTime = () => {
    const timeLeft = revealAt - Date.now()

    if (timeLeft < 0) {
      onFinished()
    } else {
      setMinutesLeft(Math.floor((timeLeft / 1000 / 60) % 60));
      setSecondsLeft(Math.floor((timeLeft / 1000) % 60));
      setProgress(100 - (timeLeft / (NUMBER_OF_MINUTES_TO_REVEAL * 600)))
    }
  }

  useEffect(() => {
    const intervalId = setInterval(getTime, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const getTimeString = () => `${String(minutesLeft).padStart(2, '0')} : ${String(secondsLeft).padStart(2, '0')}`

  return (
    <>
      <StyledGradientText sx={{ my: 2 }}>
        {getTimeString()}
      </StyledGradientText>
      <StyledLinearProgress variant="determinate" value={progress} />
    </>
  )
}