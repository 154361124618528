import { NftsPerTxSelector } from '@components';
import { flicker } from '@global/animations';
import { Colors } from '@global/colors';
import { transientProps } from '@global/utils';
import { alpha, Box, styled, Typography } from '@mui/material';

export const ContentWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledNftsPerTxSelector = styled(NftsPerTxSelector)(({ theme }) => ({
  width: '550px',
  marginTop: theme.spacing(4),

  [theme.breakpoints.down('md')]: {
    width: '90%'
  }
}));

export const MintPhaseLabel = styled(Typography, transientProps('isSoldOut'))<{ isSoldOut: boolean }>(({ theme, isSoldOut }) => ({
  width: '100%',
  textAlign: 'center',
  color: Colors.SassyPink,
  textTransform: 'uppercase',
  fontSize: theme.typography.pxToRem(isSoldOut ? 24 : 20),
  fontWeight: theme.typography.fontWeightBold,
  animation: `${flicker} 1.825s linear infinite`,
  textShadow: `0px 0px 20px ${alpha(Colors.SassyPink, 0.6)}`,

  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(14),
  }
}));

export const MintActionWrap = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
})