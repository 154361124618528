import { styled, Box, Typography, keyframes } from "@mui/material";
import { expandAndContract, flicker } from "@global/animations";
import { Colors, fadedWhite06 } from "@global/colors";
import { DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS, MINTED_TIMEOUT_MILLISECONDS } from "@global/consts";
import { Button } from "@global/components";

const grabAttention = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    color: ${Colors.White};
    transform: scale(1.0125);
  }
  100% {
    transform: scale(1);
  }
`;

export const StyledWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  minHeight: '100vh',

  [theme.breakpoints.down('sm')]: {
    justifyContent: 'flex-start',
  }
}));

export const SuccessfulMintText = styled(Typography)(({ theme }) => ({
  width: '100%',
  textShadow: '0px 0px 30px rgba(255, 145, 250, 0.6)',
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.pxToRem(48),
  color: Colors.White,
  textTransform: 'uppercase',
  animation: `${flicker} ${DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS / 2}ms normal 2 forwards ${MINTED_TIMEOUT_MILLISECONDS / 3}ms`,

  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.pxToRem(30),
  }
}))

export const SuccessfulMintSubtext = styled(Typography)(({ theme }) => ({
  color: Colors.SassyPink,
  fontSize: theme.typography.pxToRem(20),

  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.pxToRem(16),
  }
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  '.MuiTypography-root': {
    fontWeight: theme.typography.fontWeightExtraBold
  },
}))

export const StyledTextWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  maxWidth: '80%',
  textAlign: 'center',
  animation: `
    ${expandAndContract(-0.025)} 15s linear infinite
  `,

  [theme.breakpoints.down('sm')]: {
    maxWidth: '97.5%',
  }
}))

const imageMaxHeightPixels = 240;

export const StyledValentineImage = styled('img')(({ theme }) => ({
  paddingRight: '20%',
  maxWidth: '100%',
  maxHeight: `${imageMaxHeightPixels}px`,

  [theme.breakpoints.down('sm')]: {
    maxWidth: '60%',
  }
}))


export const StyledReadyTxImage = styled('img')(({ theme }) => ({
  // boxShadow: '0px 0px 30px rgba(255, 145, 250, 0.6)',
  maxWidth: '125px',
  maxHeight: `${imageMaxHeightPixels}px`,

  [theme.breakpoints.down('sm')]: {
    maxWidth: '100px',
  }
}))


export const MoreCompactText = styled('span')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    width: "97.5%",
    marginLeft: '1.25%',
    marginRight: '1.25%',
    display: 'block'
  }
}))

export const InfoText = styled(Typography)(({ theme }) => ({
  color: fadedWhite06,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(6),
  animation: `${grabAttention} 2.5s normal forwards ${MINTED_TIMEOUT_MILLISECONDS / 1.5}ms`,
  textAlign: 'center',

  '.light-white-text': {
    color: Colors.White,
    fontWeight: theme.typography.fontWeightLight,
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.pxToRem(14)
  }
}))

export const GoToWebsiteWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: Colors.White,
  marginTop: theme.spacing(3),

  '&:hover': {
    cursor: 'pointer'
  },

  'img': {
    marginRight: theme.spacing(1)
  }
}));