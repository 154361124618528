import { Box } from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useEffect, useState } from "react";
import { chunks } from "./helpers/chunks";
import { CarouselImagesWrap, CarouselWrap, ArrowWrapper, ArrowWrapperInner, StyledButton } from "./styles";
import { ValentinesCarouselProps } from "./types";
import { getNFTsMetadata } from "@component/web3-provider/helpers/get-nfts-metadata";
import { NftMetadata } from "@global/types";
import { RevealedValentine } from "./revealed-valentine";
import { MAX_NFTS_TO_SHOW_AT_ONCE } from "./const";
import { HideIn } from "@global/components";


export const ValentinesCarousel: React.FC<ValentinesCarouselProps> = ({ tokenIDs, ...wrapperProps }) => {
  const [selectedChunkIdx, setSelectedChunkIdx] = useState(0);

  const [revealedTokens, setRevealedTokens] = useState<NftMetadata[]>([]);

  const tokenChunks = tokenIDs.length === 1 ? 1 : MAX_NFTS_TO_SHOW_AT_ONCE;
  const carouselChunks = [...chunks(revealedTokens, tokenChunks)];
  const carouselChunksMobile = [...chunks(revealedTokens, 1)];

  const handleSelect = (e: any) => {
    const { direction } = e.target.dataset;

    const newIdx = direction === 'left' ?
      selectedChunkIdx === 0 ? carouselChunks.length - 1 : selectedChunkIdx - 1 :
      selectedChunkIdx === carouselChunks.length - 1 ? 0 : selectedChunkIdx + 1

    setSelectedChunkIdx(newIdx);
  }

  useEffect(() => {
    if (tokenIDs) {
      getNFTsMetadata(tokenIDs, true)
        .then((nftData: NftMetadata[]) => {
          setRevealedTokens(nftData)
        }).catch((error) => {
          console.error(error);
        });
    }
  }, [tokenIDs]);

  return (
    <CarouselWrap {...wrapperProps}>
      {
        tokenIDs.length > 1 &&
        <StyledButton data-direction='left' onClick={handleSelect} sx={{ left: 0 }}>
          <ArrowWrapper>
            <ArrowWrapperInner />
            <KeyboardArrowLeftIcon />
          </ArrowWrapper>
        </StyledButton>
      }
      <HideIn view="down.sm">
        <CarouselImagesWrap>
          {
            carouselChunks.map((chunk, chunkIdx) => (
              <Box
                key={`chunk-${chunkIdx}-${chunk[0].id}`}
                sx={{
                  width: '100%',
                  display: selectedChunkIdx === chunkIdx ? 'flex' : 'none',
                  justifyContent: 'space-around'
                }}
              >
                {
                  chunk.map((nft, chunkItemIdx) => (
                    <RevealedValentine
                      key={nft.id}
                      onlyOne={tokenChunks === 1}
                      id={nft.id!}
                      src={nft.imageSrc!}
                      isSelected={tokenChunks === MAX_NFTS_TO_SHOW_AT_ONCE ? chunkItemIdx === 1 : true}
                    />
                  ))
                }
              </Box>
            ))
          }
        </CarouselImagesWrap>
      </HideIn>
      <HideIn view="up.sm">
        <CarouselImagesWrap>
          {
            carouselChunksMobile.map((chunk, chunkIdx) => (
              <Box
                key={`chunk-${chunkIdx}-${chunk[0].id}`}
                sx={{
                  width: '100%',
                  display: selectedChunkIdx === chunkIdx ? 'flex' : 'none',
                  justifyContent: 'space-around'
                }}
              >
                {
                  chunk.map((nft) => (
                    <RevealedValentine
                      key={nft.id}
                      onlyOne
                      isSelected
                      id={nft.id!}
                      src={nft.imageSrc!}
                    />
                  ))
                }
              </Box>
            ))
          }
        </CarouselImagesWrap>
      </HideIn>
      {
        tokenIDs.length > 1 &&
        <StyledButton data-direction='right' onClick={handleSelect} sx={{ right: 0 }}>
          <ArrowWrapper>
            <ArrowWrapperInner />
            <KeyboardArrowRightIcon />
          </ArrowWrapper>
        </StyledButton>
      }
    </CarouselWrap >
  )
};