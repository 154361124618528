import { useState, useEffect, useContext, useRef } from 'react';
import { ButtonProps, Fade } from '@mui/material';
import MetaMaskOnboarding from '@metamask/onboarding';
import { BtnText } from './consts';
import { Web3Context } from '@component/web3-provider/context';
import { StyledButton } from './styles';
import { fadeTimeoutSettings } from '@global/consts';

export const MetamaskButton = (props: ButtonProps) => {
  const { account, mintCheck, connectToWallet } = useContext(Web3Context);

  const [buttonText, setButtonText] = useState(BtnText.InstallMetamask)

  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (!MetaMaskOnboarding.isMetaMaskInstalled()) {
      setButtonText(BtnText.InstallMetamask);
    } else if (!account) {
      setButtonText(BtnText.ConnectWallet);
    }
  }, [account, mintCheck?.isEligible]);

  return (
    <Fade
      unmountOnExit
      in={!account}
      timeout={fadeTimeoutSettings}
    >
      <StyledButton
        {...props}
        size="large"
        ref={buttonRef}
        onClick={connectToWallet}
      >
        {buttonText}
      </StyledButton>
    </Fade>
  )
};