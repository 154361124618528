import { Box, styled } from "@mui/material";

export const PageWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingTop: theme.spacing(4),
  overflow: 'hidden',

  [theme.breakpoints.down('sm')]: {
    justifyContent: 'flex-start',
  }
}));

export const Main = styled('main')(({ theme }) => ({
  maxWidth: '100vw',
  minHeight: '100vh',
  position: 'relative',
  padding: theme.spacing(4, 3, 0),
}));