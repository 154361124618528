import { WalletStatusDropdown } from "@components";
import { FlexWrap, StyledHeaderWrap, StyledHomaGamesLogo, StyledManifestoCTA, StyledSocials } from "./styles";
import { SocialId } from '../../../global/components/socials/types';

export const Header = () =>
  <StyledHeaderWrap>
    <FlexWrap>
      <a
        target="_blank"
        href="https://homagang.xyz" rel="noreferrer"
      >
        <StyledHomaGamesLogo header sx={{ mr: 6 }} />
      </a>
      <StyledManifestoCTA />
    </FlexWrap>
    <FlexWrap>
      <StyledSocials
        sx={{ mr: 2.5 }}
        showOnly={[SocialId.OpenSea, SocialId.Twitter, SocialId.Discord, SocialId.Website]}
      />
      <WalletStatusDropdown />
    </FlexWrap>
  </StyledHeaderWrap >