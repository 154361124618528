import { ColumnWrap } from '@global/components';
import { StyledWrapper, StyledCountText, StyledExplainerText, StyledGradientText } from './styles';
import { DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS } from '@global/consts';
import { Box, Fade } from '@mui/material';
import { TestIDs } from '@global/consts/test-ids';
import { RemainingCounterProps } from './types';

export const RemainingCounter: React.FC<RemainingCounterProps> = ({
  doneCount, doneText, totalCount, remainingText, ...wrapperProps
}) => {
  const isReadyToShow = doneCount >= 0;

  return (
    <StyledWrapper {...wrapperProps}>
      <Fade
        in={isReadyToShow}
        timeout={DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS * 4}
      >
        <ColumnWrap>
          <Box>
            <StyledGradientText data-test-id={TestIDs.RemainingCounter.TotalDoneText}>
              {String(doneCount ?? 0).padStart(String(totalCount).length, "0")}
            </StyledGradientText>
          </Box>
          <StyledExplainerText>
            {doneText ?? 'Done'}
          </StyledExplainerText>
        </ColumnWrap>
      </Fade>
      <Fade
        in={isReadyToShow}
        timeout={DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS * 5}
      >
        <StyledCountText
          jump
          sx={{ mx: 2 }}
        >
          /
        </StyledCountText>
      </Fade>
      <Fade
        in={isReadyToShow}
        unmountOnExit
        timeout={DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS * 6}
      >
        <ColumnWrap>
          <StyledCountText jump data-test-id={TestIDs.RemainingCounter.TotalRemainingText}>
            {String(totalCount - (doneCount ?? 0)).padStart(String(totalCount).length, "0")}
          </StyledCountText>
          <StyledExplainerText>
            {remainingText ?? 'Remaining'}
          </StyledExplainerText>
        </ColumnWrap>
      </Fade>
    </StyledWrapper>
  )
}
