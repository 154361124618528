import { Colors } from '@global/colors';
import { Box, styled, Typography } from '@mui/material';
import { transientProps } from '@global/utils';

export const ContentWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  maxWidth: '85%',
  marginLeft: '7.5%',
  marginRight: '7.5%',

  [theme.breakpoints.down('sm')]: {
    maxWidth: '90%',
    marginLeft: '5%',
    marginRight: '5%',
  }
}));

export const MyValentinesText = styled(Typography)(({ theme }) => ({
  alignSelf: 'flex-start',
  marginBottom: theme.spacing(3),
  color: '#E5E5E5',
  textTransform: 'capitalize',
  fontSize: theme.typography.pxToRem(24),
  fontWeight: theme.typography.fontWeightMedium,
  textShadow: '0px 0px 40px rgba(255, 145, 250, 0.6)',
}))

export const ValentinesWrap = styled(Box, transientProps('smallNumbers'))<{ smallNumbers?: boolean }>(({ theme, smallNumbers }) => ({
  marginTop: theme.spacing(4),
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: smallNumbers ? 'flex-start' : 'space-evenly',
}));

export const ValentineStatsWrap = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(5),
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  lineHeight: theme.spacing(6),

  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }
}));

export const StyledRevealedText = styled(Typography)(({ theme }) => ({
  color: Colors.White,
  fontSize: theme.typography.pxToRem(16),
  display: 'inline-block',

  'span': {
    color: Colors.SassyPink,
  },
}));