import { Box, IconButton, styled } from "@mui/material";
import { fadedWhite03, fadedWhite08 } from "@global/colors";
import { DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS } from "@global/consts";

export const SocialsWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gridGap: theme.spacing(2.5)
}))

const btnSizePixels = 44;

export const StyledSocialButton = styled(IconButton)({
  position: 'relative',
  border: `1px solid ${fadedWhite03}`,
  borderRadius: 0,
  width: `${btnSizePixels}px`,
  height: `${btnSizePixels}px`,

  transition: `border-color ${DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS}ms ease-in`,

  '&:hover': {
    borderColor: fadedWhite08,
  },
})

export const Empty = styled(Box)(({ theme }) => ({
  visibility: 'hidden',

  [theme.breakpoints.up('sm')]: {
    display: 'none'
  }
}));