import { STAKED_VALENTINES_KEY } from "@component/web3-provider/consts";
import { Web3 } from "./web3";

export const removeStakedFromLocalStorage = (): void => {
  const stakedInfoJSON = localStorage.getItem(STAKED_VALENTINES_KEY);

  if (stakedInfoJSON !== null) {
    const stakedMap = JSON.parse(stakedInfoJSON);

    delete stakedMap[Web3.account.toLowerCase()];

    localStorage.setItem(STAKED_VALENTINES_KEY, JSON.stringify(stakedMap));
  }
}