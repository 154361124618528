import { Box, Fade } from "@mui/material";
import { Button, HideIn } from '@global/components'
import {
  ContainerBox, SelectionCTA,
  MyValentinesText, StyledRevealedText, StyledMobileButton
} from "./styles";
import { ValentinesSelectorProps } from "./types";
import { MobileSelectOptionsDropdown } from "./mobile-select-options-dropdown";
import { DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS } from "@global/consts";


export const ValentinesSelector: React.FC<ValentinesSelectorProps> = ({
  canReveal,
  selectedIds,
  onSelectAll,
  onDeselectAll,
  allAvailableNFTsIds,
  onConfirmReveal,
  totalRevealed,
  totalUnrevealed
}) =>
  <>
    <Fade in timeout={DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS * 2}>
      <HideIn view="up.sm">
        <ContainerBox>
          <Box>
            <MyValentinesText variant="h6">
              My Valentines ({allAvailableNFTsIds})
            </MyValentinesText>

            <StyledRevealedText>
              Revealead <span>{totalRevealed}</span>
            </StyledRevealedText>
            <StyledRevealedText sx={{ ml: 1 }}>
              Unevealead <span>{totalUnrevealed}</span>
            </StyledRevealedText>
          </Box>

          <MobileSelectOptionsDropdown
            onSelectAll={onSelectAll}
            onDeselectAll={onDeselectAll}
          />
        </ContainerBox>
        <StyledMobileButton
          disabled={!canReveal}
          onClick={onConfirmReveal}
          boldText
        >
          Reveal {selectedIds.length}
        </StyledMobileButton>
      </HideIn>
    </Fade>

    <Fade in timeout={DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS * 2}>
      <HideIn view="down.sm">
        <ContainerBox>
          <Box>
            <SelectionCTA onClick={onSelectAll}>Select All</SelectionCTA>
            <SelectionCTA onClick={onDeselectAll}>Deselect All</SelectionCTA>
          </Box>
          <Box sx={{ alignItems: 'center', padding: '6px 12px' }}>
            {/* <SelectedNumberText>Selected ({selectedIds.length})</SelectedNumberText> */}
            <Button
              size="small"
              disabled={!canReveal}
              onClick={onConfirmReveal}
              boldText
            >
              Reveal {selectedIds.length}
            </Button>
          </Box>
        </ContainerBox>
      </HideIn>
    </Fade>
  </>