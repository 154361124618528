import {styled, Box, Typography} from "@mui/material";
import {LONG_ANIMATION_DURATION_SECONDS} from "./consts";
import {Colors} from "@global/colors";
import {expandAndContract, hover} from "@global/animations";

export const StyledHeader = styled(Typography)(({theme}) => ({
  background: 'linear-gradient(268.48deg, #FFFFFF 1.31%, #FFFFFF 1.32%, rgba(255, 255, 255, 0.79) 98.52%, rgba(255, 255, 255, 0.59) 98.52%)',
  'WebkitBackgroundClip': 'text',
  'WebkitTextFillColor': 'transparent',
  'backgroundClip': 'text',
  'textFillColor': 'transparent',
  'textShadow': '0px 0px 40px rgba(255, 145, 250, 0.6)',

  fontSize: theme.typography.pxToRem(72),
  fontWeight: theme.typography.fontWeightExtraBold,

  animation: `
    ${expandAndContract(-0.05)} ${LONG_ANIMATION_DURATION_SECONDS}s linear infinite
  `,

  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.pxToRem(36),
    paddingLeft: '48px',
    paddingRight: '48px',
  }
}));

export const StyledSubHeader = styled(Typography)(({theme}) => ({
  textTransform: 'capitalize',
  fontSize: theme.typography.pxToRem(20),
  fontWeight: theme.typography.fontWeightRegular,
  textShadow: '0px 0px 40px rgba(255, 145, 250, 0.6)',

  animation: `
    ${expandAndContract(0.1)} ${LONG_ANIMATION_DURATION_SECONDS}s linear infinite reverse
  `,

  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.pxToRem(12),
    paddingLeft: '48px',
    paddingRight: '48px',
  }
}));

export const StyledWrap = styled(Box)(({theme}) => ({
  textTransform: 'uppercase',
  color: Colors.DumbGrey,
  textAlign: 'center',

  animation: `
    ${hover} ${LONG_ANIMATION_DURATION_SECONDS * 1.5}s linear infinite
  `,
}));
