import { useState } from 'react';
import { Grow } from '@mui/material';
import { DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS } from '@global/consts';

import { InfoContainer, InfoCTA, InfoText } from './styles'
import { ToToggle } from './consts';
import { TestIDs } from '@global/consts/test-ids';
import { HideIn } from '@global/components';

export const EligibilityInfo = () => {
  const [isGeneralInfoExpanded, setIsGeneralInfoExpanded] = useState(false);
  const [isMobileInfoExpanded, setIsMobileInfoExpanded] = useState(false);

  const handleToggle = (toToggle: ToToggle) => {
    toToggle === ToToggle.GeneralInfo ?
      setIsGeneralInfoExpanded(!isGeneralInfoExpanded) :
      setIsMobileInfoExpanded(!isMobileInfoExpanded)
  }

  return (
    <>
      <InfoCTA
        onClick={() => handleToggle(ToToggle.GeneralInfo)}
        data-test-id={TestIDs.MintEligibility.EligibilityInfoCta}
      >
        Who is eligible?
      </InfoCTA>
      <Grow
        unmountOnExit
        in={isGeneralInfoExpanded}
        timeout={DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS}
      >
        <InfoContainer>
          <InfoText>
            Currently only people on the Homalist are eligible to mint. {' '}
            <br className='space' />
            Connect your wallet to check if you are eligible.
          </InfoText>
        </InfoContainer>
      </Grow>
      <HideIn view="up.sm">
        <InfoCTA
          sx={{ opacity: 0.8, mt: 2 }}
          onClick={() => handleToggle(ToToggle.MobileOnlyInfo)}
        >
          Having trouble connecting your wallet?
        </InfoCTA>
        <Grow
          unmountOnExit
          in={isMobileInfoExpanded}
          timeout={DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS}
        >
          <InfoContainer>
            <InfoText>
              In order to mint on mobile please open the Metamask app and access this website through the Metamask browser.
            </InfoText>
          </InfoContainer>
        </Grow>
      </HideIn>
    </>
  )
}
