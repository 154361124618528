import React, { useState } from "react";
import { GradientText } from "../gradient-text/gradient-text";
import { ButtonProps } from "./types";
import { StyledButton, StyledButtonHoverOverlay } from './styles';

export const Button: React.ForwardRefExoticComponent<ButtonProps> = React.forwardRef(({ children, ...otherProps }, ref) => {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const handleMouseOver = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsOverlayVisible(e.type === 'mouseover');
  }

  return (
    <StyledButton
      ref={ref}
      {...otherProps}
      onMouseOut={handleMouseOver}
      onMouseOver={handleMouseOver}
    >
      <GradientText>
        {children}
      </GradientText>
      <StyledButtonHoverOverlay display={isOverlayVisible} />
    </StyledButton>
  )
});