import { useState } from "react";
import { PageContext } from "./context"
import { Error, InfoNotification } from './components';
import { Notification } from "./types";
import { Maybe, NotificationType } from "@global/types";

// Note: Generic info notification is used to display errors to user instead of error component 
export const PageProvider = ({ children }: { children: any }) => {
  const [error, setError] = useState<Maybe<string>>(null);
  const [notification, setNotification] = useState<Maybe<Notification>>(null);

  const handleCloseError = () => {
    setError(null)
  }

  const handleCloseNotification = () => {
    setNotification(null)
  }

  const handleError = (text: string) => setNotification({ type: NotificationType.GENERIC, data: { text } })

  return (
    <PageContext.Provider
      value={{
        setError,
        setNotification,
        handleError
      }}
    >
      {error &&
        <Error
          onClose={handleCloseError}
          error={error}
        />
      }
      {notification?.type &&
        <InfoNotification
          {...notification}
          onClose={handleCloseNotification}
        />
      }
      {children}
    </PageContext.Provider>
  )
};



