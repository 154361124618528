import { Web3 } from "@global/utils";
import { WalletStake } from "../types";
import { getStakedTokenIDs } from "./get-staked-token-ids";

export const getWalletStake = async (): Promise<WalletStake | null> => {
  try {
    const stakedTokenIDs = await getStakedTokenIDs();

    if (!stakedTokenIDs.length) {
      return null;
    }

    const stakedToken = await Web3.contracts.staking.stakedTokens(stakedTokenIDs[stakedTokenIDs.length - 1]);

    return {
      staked: stakedTokenIDs,
      revealAt: stakedToken.minimumStakeDurationEndTimestamp.toNumber() * 1000,
    }
  } catch (error) {
    console.error('-- getWalletStake() --')
    console.error(error)
    console.error('-- getWalletStake() --')

    return null;
  }
};