import React, { useContext, useEffect, useState } from "react";
import WalletIcon from '@mui/icons-material/AccountBalanceWalletOutlined';

import { GradientFill } from "@component/gradient-fill/gradient-fill";
import { Box, ButtonProps, Fade, Typography } from "@mui/material";
import { HideIn, HoverOverlay } from "@global/components"
import { Web3Context } from "@component/web3-provider/context";
import {
  StyledDropdownBtn, StyledDropdownContainer, StyledDownArrowIcon,
  ChangeWalletButton, StyledGradientText, ShortenedAddressText,
  StyledConnectedText, StyledMainWrap, DisconnectButton
} from './styles'
import { shortenAddress } from "./helpers/shorten-address";
import { EXPAND_TOGGLE_ANIMATION_TIMEOUT_MILLISECONDS } from "./consts";
import { TestIDs } from "@global/consts/test-ids";

export const WalletStatusDropdown = (props: ButtonProps) => {
  const { account, changeWallet, disconnectWallet } = useContext(Web3Context);
  const [isDropdownExpanded, setIsDropdownExpanded] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const containerRef = React.useRef(null);

  const handleClick = async () => {
    if (!account) {
      await changeWallet()
    } else {
      setIsDropdownExpanded(!isDropdownExpanded);
    }
  }

  const handleWalletChange = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    await changeWallet()
  }

  const handleMouseOver = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsOverlayVisible(e.type === 'mouseover');
  }

  useEffect(() => {
    if (!account) {
      setIsDropdownExpanded(false);
    }
  }, [account])

  return (
    <StyledMainWrap data-test-id={TestIDs.WalletStatusDropdown.Wrapper}>
      <StyledDropdownBtn
        hovered={isOverlayVisible}
        ref={containerRef}
        startIcon={
          <GradientFill icon={<WalletIcon />} />
        }
        endIcon={
          !!account ?
            <StyledDownArrowIcon reverse={isDropdownExpanded} /> :
            null
        }
        onClick={handleClick}
        onMouseOut={handleMouseOver}
        onMouseOver={handleMouseOver}
        {...props}
      >
        <StyledGradientText data-test-id={TestIDs.WalletStatusDropdown.ConnectionText}>
          {!account ? `NOT CONNECTED` : `CONNECTED ${shortenAddress(account)}`}
        </StyledGradientText>
        <HoverOverlay display={isOverlayVisible} />
      </StyledDropdownBtn>
      {
        account &&
        <Fade
          in={isDropdownExpanded}
          timeout={EXPAND_TOGGLE_ANIMATION_TIMEOUT_MILLISECONDS}
        >
          <Box>
            <StyledDropdownContainer hovered={isOverlayVisible}>
              <StyledConnectedText data-test-id={TestIDs.WalletStatusDropdown.ConnectedWithText}>
                Connected with Metamask wallet {' '}
                <ShortenedAddressText component='span'>
                  {shortenAddress(account)}
                </ShortenedAddressText>
              </StyledConnectedText>
              <HideIn view="down.md">
                <ChangeWalletButton
                  fullWidth
                  sx={{ mt: 2 }}
                  onClick={handleWalletChange}
                  data-test-id={TestIDs.WalletStatusDropdown.ChangeWalletBtn}
                >
                  Change wallet
                </ChangeWalletButton>
              </HideIn>
              <DisconnectButton
                fullWidth
                onClick={disconnectWallet}
                data-test-id={TestIDs.WalletStatusDropdown.DisconnectWalletBtn}
              >
                <Typography>
                  Disconnect
                </Typography>
              </DisconnectButton>
              <HoverOverlay display={isOverlayVisible} />
            </StyledDropdownContainer>
          </Box>
        </Fade>
      }
    </StyledMainWrap>
  )
}
