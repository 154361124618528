import { DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS } from '@global/consts';
import { Fade } from '@mui/material';
import { BoxProps } from '@mui/system';
import React, { useState, useEffect, useRef } from 'react'
import { valentineImages, VALENTINE_CAROUSEL_ANIMATION_MILLISECONDS } from './consts';
import { ValentineImageWrap, ValentineSliderContent, ValentineSliderWrap } from './styles';

export const ValentineSlider: React.FC<BoxProps> = (props) => {
  const animationSliderRef = useRef<HTMLDivElement>(null);
  const [loadedImages, setLoadedImages] = useState(0);
  const [isReadyToShow, setIsReadyToShow] = useState(false);

  const handleImageLoad = () => {
    setLoadedImages((imageCount: number) => (imageCount + 1));
  };

  useEffect(() => {
    if (!isReadyToShow && loadedImages >= Math.floor(50 / 100 * valentineImages.length)) {
      setIsReadyToShow(true);
    }
  }, [loadedImages])

  useEffect(() => {
    const valentineCarouselEl = animationSliderRef.current;

    if (!valentineCarouselEl) return;

    let start: any;

    window.requestAnimationFrame(function step(timestamp) {
      if (!start) {
        start = timestamp
      }

      let time = timestamp - start

      let percent = Math.min(time / VALENTINE_CAROUSEL_ANIMATION_MILLISECONDS, 1)

      valentineCarouselEl.style.transform = `translateX(-${percent * 100}%)`;

      if (percent >= 0.5) {
        start = undefined;
      }

      window.requestAnimationFrame(step)
    });
  }, [animationSliderRef.current]);

  return (
    <ValentineSliderWrap {...props}>
      <ValentineSliderContent ref={animationSliderRef}>
        {[...valentineImages, ...valentineImages].map((imageSrc: string, idx: number) =>
          <Fade
            in={isReadyToShow}
            key={`${imageSrc}-${idx}`}
            timeout={DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS + (idx * 500)}
          >
            <ValentineImageWrap>
              <img
                src={imageSrc}
                alt="Valentine NFT"
                onLoad={handleImageLoad}
              />
            </ValentineImageWrap>
          </Fade>
        )}
      </ValentineSliderContent>
    </ValentineSliderWrap>
  )
}
