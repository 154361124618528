import { stakingContractAddress } from "@component/web3-provider/contracts";
import { Web3 } from "../../../global/utils";

export const setApprovalForAll = async (): Promise<void> => {
  const { provider, account, contracts: { minting: mintContract } } = Web3;

  const isAlreadyApproved = await mintContract.isApprovedForAll(account, stakingContractAddress);
  console.log('isAlreadyApproved', isAlreadyApproved)
  if (isAlreadyApproved) {
    return Promise.resolve();
  }

  const feeData = await provider.getFeeData();
  const signer = await provider.getSigner(account);
  const mintWithSigner = await mintContract.connect(signer);

  const txGasLimitForApprovalTx = await mintWithSigner.estimateGas.setApprovalForAll(stakingContractAddress, true);

  const approvalTx = await mintWithSigner.setApprovalForAll(
    stakingContractAddress, true,
    {
      gasLimit: txGasLimitForApprovalTx.mul(2),
      maxFeePerGas: feeData.maxFeePerGas,
      maxPriorityFeePerGas: feeData.maxPriorityFeePerGas,
    }
  );

  await approvalTx.wait();
};

export const stakeTokens = async (tokenIDsToReveal: number[]) => {
  const { provider, account, contracts: { staking: stakingContract } } = Web3;

  const feeData = await provider.getFeeData();
  const signer = await provider.getSigner(account);

  const stakingWithSigner = await stakingContract.connect(signer);
  const txGasLimitForStakingTx = await stakingWithSigner.estimateGas.stakeToken(tokenIDsToReveal);

  const stakingTx = await stakingWithSigner.stakeToken(
    tokenIDsToReveal,
    {
      gasLimit: txGasLimitForStakingTx.mul(2),
      maxFeePerGas: feeData.maxFeePerGas,
      maxPriorityFeePerGas: feeData.maxPriorityFeePerGas,
    });

  await stakingTx.wait();

  const stakedToken = await stakingContract.stakedTokens(tokenIDsToReveal[0]);

  return stakedToken.minimumStakeDurationEndTimestamp.toNumber() * 1000;
};

export const revealHandler = async (tokenIDsToReveal: number[]): Promise<number> => {
  try {
    const { provider, account, contracts: { minting: mintContract, staking: stakingContract } } = Web3;

    const feeData = await provider.getFeeData();
    const signer = await provider.getSigner(account);
    const mintWithSigner = await mintContract.connect(signer);

    const txGasLimitForApprovalTx = await mintWithSigner.estimateGas.setApprovalForAll(stakingContractAddress, true);

    const approvalTx = await mintWithSigner.setApprovalForAll(
      stakingContractAddress, true,
      {
        gasLimit: txGasLimitForApprovalTx.mul(2),
        maxFeePerGas: feeData.maxFeePerGas,
        maxPriorityFeePerGas: feeData.maxPriorityFeePerGas,
      }
    );

    await approvalTx.wait();

    const stakingWithSigner = await stakingContract.connect(signer);
    const txGasLimitForStakingTx = await stakingWithSigner.estimateGas.stakeToken(tokenIDsToReveal);

    const stakingTx = await stakingWithSigner.stakeToken(
      tokenIDsToReveal,
      {
        gasLimit: txGasLimitForStakingTx.mul(2),
        maxFeePerGas: feeData.maxFeePerGas,
        maxPriorityFeePerGas: feeData.maxPriorityFeePerGas,
      });

    await stakingTx.wait();

    const stakedToken = await stakingContract.stakedTokens(tokenIDsToReveal[0]);

    return stakedToken.minimumStakeDurationEndTimestamp.toNumber() * 1000;
  } catch (error: unknown) {
    console.error('-- handleReveal error --');
    console.error(error)
    console.error('-- handleReveal error --');

    return -1;
  }
}