import { Box, styled } from "@mui/material";

export const ValentineSliderWrap = styled(Box)({
  width: '100%',
  height: '150px',
  position: 'relative',
});

export const ValentineSliderContent = styled(Box)({
  top: 0,
  bottom: 0,
  display: 'flex',
  position: "absolute",
});

export const ValentineImageWrap = styled(Box)(({ theme }) => ({
  width: '150px',
  height: '150px',
  justifySelf: 'center',
  marginRight: theme.spacing(2),
  marginLeft: theme.spacing(2),
  filter: 'drop-shadow(0px 4px 15px rgba(255, 255, 255, 0.25))',

  'img': {
    width: '100%',
    height: '100%',
    borderRadius: theme.spacing(3),
  }
}));