import React, { Ref, useContext, useEffect, useState } from "react"
import InfoIcon from '@mui/icons-material/Info';
import { Action, NftsPerTxSelectorProps } from "./types";
import { ColumnWrap } from "@global/components";
import { Web3Context } from "@component/web3-provider/context";
import { MIN_NFTS_PER_TX } from "./consts";
import { DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS, fadeTimeoutSettings, MAX_MINT_PER_USER, NFT_PRICE } from "@global/consts";
import {
  SelectorWrap, StyledAddIcon, StyledRemoveIcon,
  StyledTextField, Divider, TotalPriceWrap,
  StyledTotalLabel, StyledTotalPrice, StyledIconButton,
  PriceDetailsWrap,
  TransactionFeesDisclaimer
} from './styles';
import { Fade } from "@mui/material";
import { TestIDs } from "@global/consts/test-ids";

export const NftsPerTxSelector = React.forwardRef((
  { onChange, ...wrapperProps }: NftsPerTxSelectorProps,
  ref: Ref<HTMLDivElement> | undefined
) => {
  const { account, mintCheck } = useContext(Web3Context)

  const [count, setCount] = useState(0);

  const handleNftCountChange = (action: Action) => {
    const updatedCount = action === Action.Add ? count + 1 : count - 1

    if (updatedCount > mintCheck?.eligibleToMintCount! || updatedCount < MIN_NFTS_PER_TX) {
      return;
    }

    setCount(updatedCount)
  };

  const [shouldTextBlink, setShouldTextBlink] = useState(false)

  useEffect(() => {
    setShouldTextBlink(true)
    onChange(count);

    setTimeout(() => {
      setShouldTextBlink(false)
    }, DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS)
  }, [count]);

  useEffect(() => {
    if (mintCheck !== null && mintCheck.isEligible) {
      setCount(mintCheck.eligibleToMintCount);
    }
  }, [mintCheck?.isEligible]);

  if (!account) return null;

  const requiresPlusAndMinus = mintCheck?.eligibleToMintCount! > MAX_MINT_PER_USER;

  return (
    <Fade
      in={!!mintCheck?.isEligible}
      unmountOnExit
      timeout={fadeTimeoutSettings}
    >
      <ColumnWrap
        ref={ref}
        data-test-id={TestIDs.NftsPerTxSelector.Wrapper}
        {...wrapperProps}
      >
        <SelectorWrap>
          {
            requiresPlusAndMinus &&
            <StyledIconButton
              disabled={count <= MIN_NFTS_PER_TX}
              onClick={() => handleNftCountChange(Action.Remove)}
              data-test-id={TestIDs.NftsPerTxSelector.RemoveBtn}
            >
              <StyledRemoveIcon />
            </StyledIconButton>
          }
          <StyledTextField
            disableHoverOverlay
            value={count}
            borderWidth={3}
            borderRadius="16px"
            borderGradientDirection="bottom"
            data-test-id={TestIDs.NftsPerTxSelector.ToMintCountInputField}
            InputProps={{
              readOnly: true
            }}
          />
          {
            requiresPlusAndMinus &&
            <StyledIconButton
              disabled={count === mintCheck?.eligibleToMintCount}
              onClick={() => handleNftCountChange(Action.Add)}
              data-test-id={TestIDs.NftsPerTxSelector.AddBtn}
            >
              <StyledAddIcon />
            </StyledIconButton>
          }
        </SelectorWrap>
        <TotalPriceWrap>
          <Divider />
          <StyledTotalLabel>
            Total
          </StyledTotalLabel>
          <PriceDetailsWrap>
            <StyledTotalPrice blink={shouldTextBlink}>
              {count * NFT_PRICE} ETH
            </StyledTotalPrice>
            <TransactionFeesDisclaimer>
              <InfoIcon />
              Transaction fees apply
            </TransactionFeesDisclaimer>
          </PriceDetailsWrap>
        </TotalPriceWrap>
      </ColumnWrap>
    </Fade>
  )
});