import { Fade } from "@mui/material";
import { ValentineSlider } from "@components"
import { FADE_IN_TIMEOUT_MILLISECONDS } from "@global/consts";
import { TestIDs } from "@global/consts/test-ids";
import { ContentWrap } from './styles';
import { Web3Context } from "@component/web3-provider/context";
import { useContext } from "react";
import { NoValentinesView } from "./no-valentines";
import { NotConnectedView } from "./not-connected";

export const RevealMainView: React.FC = () => {
  const { account, walletNFTs } = useContext(Web3Context);
  const ySpacing = { sx: { my: 3.5 } };

  return (
    <Fade
      in
      unmountOnExit
      timeout={FADE_IN_TIMEOUT_MILLISECONDS}
    >
      <ContentWrap data-test-id={TestIDs.View.RevealMain}>
        {
          !account && <NotConnectedView />
        }
        {
          (account && walletNFTs && walletNFTs.length === 0) && <NoValentinesView />
        }
        <ValentineSlider {...ySpacing} />
      </ContentWrap>
    </Fade>
  )
}