export const NFT_PRICE = 0;
export const TOTAL_AMOUNT_NFTS = 7000;

export const DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS = 450;

export const FADE_IN_TIMEOUT_MILLISECONDS = 2000;

export const MINTED_TIMEOUT_MILLISECONDS = FADE_IN_TIMEOUT_MILLISECONDS * 4;

export const USER_WALLET_IS_DISCONNECTED = 'USER_WALLET_IS_DISCONNECTED';

export const fadeTimeoutSettings = {
  exit: 0,
  appear: DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS,
  enter: DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS,
};

export const DEFAULT_GAS_LIMIT_GWEI = 100000;
export const MAX_MINT_PER_USER = 1;

export const VIP_USER_MINT_INFO_NOTIFICATION_TIMEOUT_MILLISECONDS = 2000;

export const HAS_USER_MINTED_FLAG = 'has-user-minted';

export const MintPhase = {
  Inactive: 0,
  Homalist: 1,
  Public: 2,
}

export const MINTED_TX_HASH = 'user-mint-tx-hash';

export const backToWebsiteHandler = () => window.open('https://homagang.xyz/', '_self');

export const TX_HASH_PER_ACCOUNT_MAP = 'tx-hash-per-account-map';