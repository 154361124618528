import { GradientText } from "@global/components";
import { DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS } from "@global/consts";
import { Card, CardMedia, IconButton, Skeleton, styled } from "@mui/material";

export const NftTitle = styled(GradientText)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(24),
  fontWeight: theme.typography.fontWeightBold,
}))

export const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2, 3, 5),
  maxWidth: '350px',
  background: 'rgba(29, 29, 36, 0.5)',
  opacity: 0.8,
  borderRadius: '12px',
  position: 'relative',
  transition: `transform ${DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS / 2}ms ease-in-out !important`,

  '&:hover': {
    transform: 'scale(1.025)',
    cursor: 'pointer'
  }
}))

export const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  filter: 'drop-shadow(0px 0px 50px rgba(255, 145, 250, 0.25))'
}))

export const StyledPlayButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(7),
  right: theme.spacing(5),
  color: 'rgba(190, 190, 190, 0.6)',
  transform: 'scale(1.75)',
  opacity: 0.8,

  [theme.breakpoints.down('sm')]: {
    transform: 'scale(1.25)',
    bottom: theme.spacing(6),
    right: theme.spacing(4),
  }
}));

export const ValentineSkeleton = styled(Skeleton)(({ theme }) => ({
  width: '300px',
  height: '300px',
  maxWidth: '100%',
  transform: 'none',

  [theme.breakpoints.down('sm')]: {
    maxHeight: '250px',
  }
}));