import React from "react";
import { Fade } from "@mui/material";
import { Header, Footer, AnimatedGlows } from "@components"
import { PageWrapper, Main } from "./styles"
import { FADE_IN_TIMEOUT_MILLISECONDS } from "@global/consts";

export const Layout: React.FC<{ children: React.ReactNode, hideFooter?: boolean }> = ({ children, hideFooter }) =>
  <>
    <AnimatedGlows />
    <Fade in timeout={FADE_IN_TIMEOUT_MILLISECONDS}>
      <PageWrapper>
        <Header />
        <Main>
          {children}
        </Main>
        {
          !hideFooter &&
          <Footer />
        }
      </PageWrapper>
    </Fade>
  </>