import { Web3 } from "@global/utils";
import { ethers } from "ethers";
import { stakingContractAddress } from "../contracts";
import { CountOccurenceMap } from "./types";

export const getStakedTokenIDs = async (): Promise<number[]> => {
  const transferToStakingEventsQuery = await Web3.contracts.minting.filters.Transfer(Web3.account, stakingContractAddress)
  const transferToStakingEvents = await Web3.contracts.minting.queryFilter(transferToStakingEventsQuery)

  if (transferToStakingEvents.length === 0) {
    return [];
  }

  const transferFromStakingEventsQuery = await Web3.contracts.minting.filters.Transfer(stakingContractAddress, Web3.account)
  const transferFromStakingEvents = await Web3.contracts.minting.queryFilter(transferFromStakingEventsQuery)

  const countOccurence = (acc: CountOccurenceMap, tx: ethers.Event) => {
    const tokenId = tx.args?.tokenId.toNumber();

    return ({
      ...acc, [tokenId]: (acc[tokenId] ?? 0) + 1
    });
  };

  const transferToTokenOccurenceMap = transferToStakingEvents.reduce(countOccurence, {}) as CountOccurenceMap;
  const transferFromTokenOccurenceMap = transferFromStakingEvents.reduce(countOccurence, {}) as CountOccurenceMap;

  return Object.entries(transferToTokenOccurenceMap).reduce((acc: number[], [tokenId, tokenTransferToOccurence]: [string, number]) =>
    tokenTransferToOccurence > (transferFromTokenOccurenceMap[tokenId] ?? 0) ? [...acc, Number(tokenId)] : acc,
    []
  );
}
