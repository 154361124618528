import { Layout } from "@component/layout/layout";
import { Web3Context } from "@component/web3-provider/context";
import { useContext } from "react";
import { RevealMainView, ValentinesView } from './views';


export const App = () => {
  const { account, walletNFTs, walletStake } = useContext(Web3Context);

  const shouldShowMainView = (!account || (walletNFTs && walletNFTs.length === 0)) && !(walletStake && walletStake?.staked.length > 0);

  return (
    <Layout hideFooter={!shouldShowMainView}>
      {
        shouldShowMainView &&
        <RevealMainView />
      }
      {
        !shouldShowMainView &&
        <ValentinesView />
      }
    </Layout>
  )
}