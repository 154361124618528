import { Backdrop, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";
import { StyledCloseCTA, OverlayWrap, StyledHeader, StyledHeaderWrap, StyledSubHeader } from "./styles";
import { RevealedOverlayProps } from "./types";
import { ValentinesCarousel } from "./valentines-carousel";
import { HideIn } from "@global/components";

export const RevealedValentinesOverlay: React.FC<RevealedOverlayProps> = ({ revealedIDs }) => {
  const [open, setOpen] = useState(true);

  const handleClose = (e: any) => {
    setOpen(false);
    document.body.style.overflow = 'auto';
  };

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <OverlayWrap>
        <StyledCloseCTA onClick={handleClose}>
          <CloseIcon />
          <HideIn component="span" view="down.sm">
            <Typography>
              Close
            </Typography>
          </HideIn>
        </StyledCloseCTA>
        <StyledHeaderWrap>
          <StyledHeader>
            CONGRATS! <HideIn component="span" view="up.sm"><br /></HideIn> MEET YOUR VALENTINE{revealedIDs.length === 1 ? '' : 'S'}!
          </StyledHeader>
          <StyledSubHeader>
            You have successfully revealed {revealedIDs.length} Valentine{revealedIDs.length === 1 ? '' : 's'}.
          </StyledSubHeader>
        </StyledHeaderWrap>
        <ValentinesCarousel tokenIDs={revealedIDs} />
      </OverlayWrap>
    </Backdrop>
  )
}