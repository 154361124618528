import { MenuItem } from "@mui/material"
import { useState } from "react";
import { dropdownMenuStyles, menuItemBorder, StyledSelect } from "./styles";
import { MobileSelectOptionsDropdownProps } from "./types";

export const MobileSelectOptionsDropdown: React.FC<MobileSelectOptionsDropdownProps> = ({ onSelectAll, onDeselectAll }) => {
  const [value, setValue] = useState<'all' | 'none' | 'select'>('select');

  const handleChange = (e: any) => {
    const selection = e.target.value;
    setValue(selection);

    if (selection === 'all') {
      onSelectAll();
    } else if (selection === 'none') {
      onDeselectAll();
    }
  };

  return (
    <StyledSelect
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={value}
      label="Age"
      onChange={handleChange}
      MenuProps={{
        sx: dropdownMenuStyles
      }}
    >
      <MenuItem value='all' sx={{ borderBottom: menuItemBorder }}>Select all</MenuItem>
      <MenuItem value='none' sx={{ borderBottom: menuItemBorder }}>Deselect all</MenuItem>
      <MenuItem value='select' sx={{ borderTop: menuItemBorder }}>Select</MenuItem>
    </StyledSelect>
  )
}