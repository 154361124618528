import { Fade, CardHeader, Box } from "@mui/material";
import { ValentineNftProps } from "./types";
import { Checkbox } from "@global/components/checkbox/checkbox";
import { useState, useRef, useEffect } from "react";
import { DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS } from "@global/consts";
import { NftTitle, StyledCard, StyledCardMedia, ValentineSkeleton } from './styles';

export const ValentineNFT: React.FC<ValentineNftProps> = (
  { id, imageSrc, allowSelecting, isSelected, isRevealed, isStaking, ...wrapperProps }
) => {
  const [isReadyToShow, setIsReadyToShow] = useState(false);
  const videoRef = useRef(null)

  const handleMouseOver = () => {
    if (isStaking) return;

    const videoEl = videoRef.current as HTMLVideoElement | null;
    if (!videoEl) return;

    videoEl.play()
  }

  const handleMouseOut = () => {
    if (isStaking) return;

    const videoEl = videoRef.current as HTMLVideoElement | null;
    if (!videoEl) return;

    videoEl.pause()
  }

  useEffect(() => {
    const videoEl = videoRef.current as HTMLVideoElement | null;

    if (videoEl !== null && isStaking) {
      videoEl.play()
    }
  }, [isStaking]);


  return (
    <Fade in timeout={DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS * 2}>
      <StyledCard {...wrapperProps}>
        <CardHeader
          title={<NftTitle sx={{ ml: -2 }}>{"Valentine #" + id}</NftTitle>}
          avatar={
            allowSelecting ?
              <Checkbox label="" checked={isSelected} /> :
              null
          }
        />

        {
          !isReadyToShow &&
          <Fade in timeout={DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS * 2}>
            <ValentineSkeleton />
          </Fade>
        }


        <Fade in={isReadyToShow} timeout={DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS * 3}>
          <Box sx={{ position: !isReadyToShow ? 'absolute' : 'relative' }}>
            {
              isRevealed ?
                <StyledCardMedia
                  // @ts-ignore
                  component="img"
                  src={imageSrc}
                  onLoad={() => setIsReadyToShow(true)}
                /> :
                <StyledCardMedia
                  ref={videoRef}
                  onMouseOut={handleMouseOut}
                  onMouseOver={handleMouseOver}
                  // @ts-ignore
                  component="video"
                  src={isStaking ? 'https://hg-stage-oh-erinyes.s3.us-east-2.amazonaws.com/public/valentine-nft-drop/assets/reveal-transition.mp4' : imageSrc}
                  onLoadedData={() => setIsReadyToShow(true)}
                  loop
                  muted
                  preload="metadata"
                />
            }
          </Box>
        </Fade>
      </StyledCard>
    </Fade >
  );
} 
