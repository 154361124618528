import { flicker } from '@global/animations';
import { Colors, fadedWhite06, pinkToBlue } from '@global/colors';
import { Button } from '@global/components';
import { alpha, Box, styled, Typography } from '@mui/material';

export const ContentWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-evenly',

  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
}));


export const AreYouReadyText = styled(Typography)(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  color: Colors.SassyPink,
  textTransform: 'uppercase',
  fontSize: theme.typography.pxToRem(20),
  fontWeight: theme.typography.fontWeightBold,
  animation: `${flicker} 1.825s linear infinite`,
  textShadow: `0px 0px 20px ${alpha(Colors.SassyPink, 0.6)}`,
  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(14),
  }
}));

export const InfoCTA = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color: Colors.SassyPink,
  textDecoration: 'underline',
  marginBottom: theme.spacing(1),
  transition: 'color 0.3s ease-out',

  '&:hover': {
    cursor: 'pointer',
    color: Colors.ShyPink,
  }
}));

export const InfoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',

  padding: theme.spacing(2),
  background: pinkToBlue(0.1),
  maxWidth: '90vw',

  'p': {
    lineHeight: theme.spacing(4),
  },

  [theme.breakpoints.up('md')]: {
    maxWidth: '650px',
  }
}))

export const InfoText = styled(Typography)(({ theme }) => ({
  color: fadedWhite06,
  textAlign: 'center',
  lineHeight: theme.spacing(3),

  [theme.breakpoints.down('sm')]: {
    textAlign: 'left',
    fontSize: theme.typography.pxToRem(14),

    '.space': {
      display: 'none'
    }
  }
}))

export const BoldTextButton = styled(Button)(({ theme }) => ({
  '.MuiTypography-root': {
    fontWeight: theme.typography.fontWeightExtraBold
  },
}))