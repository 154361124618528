import { useState } from 'react';
import { HoverOverlay } from '@global/components';
import { SocialsWrap, StyledSocialButton } from './styles';
import { SocialsProps } from './types';
import { filterSocials } from './helpers/filter-socials';

export const Socials = (props: SocialsProps) => {

  const [hoveredOver, setHoveredOver] = useState('opensea');

  const handleMouseOver = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setHoveredOver((e.type === 'mouseover' && e.currentTarget.id) || '');
  }

  const handleClick = (url: string) => {
    window.open(url, '_newtab');
  }

  return (
    <SocialsWrap {...props}>
      {
        filterSocials(props.showOnly).map((social) =>
          social !== undefined &&
          (
            <StyledSocialButton
              key={social.id}
              id={social.id}
              onMouseOut={handleMouseOver}
              onMouseOver={handleMouseOver}
              onClick={() => handleClick(social.url)}
            >
              <img src={social.icon} alt={social.alt} />
              <HoverOverlay display={hoveredOver === social.id} />
            </StyledSocialButton>
          ))
      }
    </SocialsWrap>
  )
}
