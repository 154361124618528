import { Web3 } from "@global/utils";

export const getNFTsMetadata = (tokenIDs: number[], isRevealed: boolean) => {
  const nftsMetadata = tokenIDs.map(async (tokenId) => {

    if (!isRevealed) {
      return {
        id: tokenId,
        // Unrevealed video
        imageSrc: 'https://hg-prod-nv-erinyes.s3.us-east-1.amazonaws.com/public/valentine-nft-drop/mystery_video',
      }
    }

    const tokenUri = await Web3.contracts.minting.tokenURI(tokenId);

    const data = await fetch(tokenUri).then(res => {
      return res.status === 200 ? res.json() : null;
    });

    return {
      id: tokenId,
      ...(data !== null && {
        imageSrc: data.image,
        traits: data.attributes
      })
    }
  });

  return Promise.all(nftsMetadata);
};
