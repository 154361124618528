import { StyledHeader, StyledSubHeader, StyledWrap } from "./styles";
import { ViewHeaderProps } from "./types";

export const ViewHeader: React.FC<ViewHeaderProps> = ({ headerText, subHeaderText, ...wrapperProps }) =>
  <StyledWrap {...wrapperProps}>
    <StyledHeader variant="h1">
      {headerText}
    </StyledHeader>
    {
      subHeaderText &&
      <StyledSubHeader variant="h2">
        {subHeaderText}
      </StyledSubHeader>
    }
  </StyledWrap>