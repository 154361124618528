import { DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS } from "@global/consts"
import { Box, Fade } from "@mui/material"
import { RevealedImg, StyledText, ValentineWrap } from "./styles"
import { RevealedValentineProps } from "./types"

export const RevealedValentine: React.FC<RevealedValentineProps> = ({ id, src, isSelected, onlyOne }) => (
  <ValentineWrap>
    <RevealedImg
      src={src}
      isSelected={isSelected}
      onlyOne={onlyOne}
    />
    <Fade in={isSelected} unmountOnExit timeout={DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS * 3}>
      <Box>
        <StyledText>
          Valentine #{id}
        </StyledText>
      </Box>
    </Fade>
  </ValentineWrap>
)