import { HideIn, Socials } from '@global/components';
import { SocialId } from '@global/components/socials/types';
import { Box } from '@mui/material';
import {
  MainContentWrap, LogoAndSocialsWrap,
  StyledManifestoCTA, CopyrightText, StyledWrap, PrivacyPolicyAndTermsWrap,
  StyledHomaGamesLogo, SocialsWrapMobile
} from './styles';

const Space = () => <Box component="span" sx={{ mx: 1 }} />;

export const Footer = () =>
  <StyledWrap>
    <MainContentWrap>
      <LogoAndSocialsWrap>
        <StyledHomaGamesLogo />
        <HideIn view="down.md">
          <Socials sx={{ mt: 4 }} />
        </HideIn>
        <HideIn view="up.md">
          <SocialsWrapMobile sx={{ mt: 4 }}>
            <StyledManifestoCTA />
            <Socials showOnly={[SocialId.Twitter, SocialId.Discord, SocialId.OpenSea, SocialId.Website]} sx={{ mt: 3 }} />
            <Socials showOnly={[SocialId.Instagram, SocialId.TikTok, SocialId.Medium]} sx={{ mt: 2 }} />
          </SocialsWrapMobile>
        </HideIn>
      </LogoAndSocialsWrap>
      {/* <NewsletterWrap>
        <Newsletter />
      </NewsletterWrap> */}
    </MainContentWrap>

    <PrivacyPolicyAndTermsWrap>
      <a href="https://www.homagames.com/privacy" target="_blank" rel="noreferrer">
        Privacy Policy
      </a>
      <Space />
      <a href="https://www.homagames.com/terms" target="_blank" rel="noreferrer">
        Terms
      </a>
    </PrivacyPolicyAndTermsWrap>
    <CopyrightText>
      HomaGames ©️ 2022 All Rights Reserved
      <HideIn component="span" sx={{ display: 'inline' }} view="down.md" >
        <Space /> Powered by Polygon
      </HideIn>
    </CopyrightText>
  </StyledWrap >
