import { keyframes } from "@mui/material";

export const hover = keyframes`
  0% { 
    transform: translateY(0%);
  }
  25%{
    transform: translateY(1.5%);
  }
  50%{
    transform: translateY(0%);
  }
  75%{
    transform: translateY(-1.5%);
  }
  100% {
    transform: translateY(0%);
  } 
`

export const expandAndContract = (by?: number) => keyframes`
  0% { 
    transform: scale(1);
  }
  50%{
    transform: scale(${by ? 1 + by : 1.05});
  }
  100%{
    transform: scale(1);
  }
`


export const flicker = keyframes`
  0% {
    opacity:0.1;
  }
  2% {
    opacity:1;
  }
  8% {
    opacity:0.1;
  }
  9% {
    opacity:1;
  }
  12% {
    opacity:0.1;
  }
  20% {
    opacity:1;
  }
  25% {
    opacity:0.3;
  }
  30% {
    opacity:1;
  }
  70% {
    opacity:0.7;
  }
  72% {
    opacity:0.2;
  }
  77% {
    opacity:.9;
  }
  100% {
    opacity:.9;
  }
`

export const moveUp = keyframes`
  0% { 
    transform: translateY(25%);
  }
  100% {
    transform: translateY(0%);
  } 
`

export const grow = keyframes`
  0% { 
    transform: scale(1.1);
  }
  100% {
    transform: scale(1.5);
  } 
`

export const invalidate = keyframes`
  0% { 
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0.2;
  } 
`