import { HomaGamesLogo, Socials, ManifestoCTA } from "@global/components";
import { styled, Box } from "@mui/material";

export const FlexWrap = styled(Box)({
  display: 'flex',
  alignItems: 'center'
});

export const StyledHeaderWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: theme.spacing(1, 7.5),

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2)
  }
}));

export const StyledHomaGamesLogo = styled(HomaGamesLogo)(({ theme }) => ({
  maxWidth: '111px',

  [theme.breakpoints.down('md')]: {
    maxWidth: '88px',
  },

  [theme.breakpoints.down('sm')]: {
    maxWidth: '64px',
  },
}))

export const StyledManifestoCTA = styled(ManifestoCTA)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  paddingTop: theme.spacing(1),
  marginLeft: theme.spacing(-0.25),

  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}))

export const StyledSocials = styled(Socials)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }
}));