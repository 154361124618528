import { Colors } from '@global/colors'
import React from 'react'
import { GradientIconProps } from './types'

export const GradientFill: React.FC<GradientIconProps> = ({ icon }) =>
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1} gradientTransform="rotate(270)">
        <stop offset={0} stopColor={Colors.SassyPink} />
        <stop offset={1} stopColor={Colors.HomaGreen} />
      </linearGradient>
    </svg>

    {React.cloneElement(icon, { sx: { fill: "url(#linearColors)" } })}
  </>
