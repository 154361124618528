import { Fade, Grow } from "@mui/material";
import { MetamaskButton } from "@components"
import { DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS, TOTAL_AMOUNT_NFTS } from "@global/consts";
import { TestIDs } from "@global/consts/test-ids";
import { AreYouReadyText, ContentWrap, InfoContainer, InfoCTA, InfoText } from './styles';
import { Web3Context } from "@component/web3-provider/context";
import { useContext, useState } from "react";
import { HideIn, RemainingCounter, ViewHeader } from "@global/components";

export const NotConnectedView = () => {
  const { totalRevealed } = useContext(Web3Context);

  const [isMoreInfoExpanded, setIsMoreInfoExpanded] = useState(false);

  const ySpacing = { sx: { my: 3.5 } };

  return (
    <Fade in unmountOnExit timeout={DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS}>
      <ContentWrap>
        <ViewHeader
          headerText="THE REVEAL IS READY FOR YOU"
          subHeaderText={
            <>
              <HideIn component="span" view="down.sm">
                Discover which Valentine you hold. Or don’t. The choice is yours.
                <br />
                Are you ready to reveal? Or do you want to keep the mystery going? It’s all to play for.
              </HideIn>
              <HideIn component="span" view="up.sm" sx={{ whiteSpace: 'nowrap' }}>
                Time has come to finally dicover your valentine!
              </HideIn>
            </>
          }
        />
        <AreYouReadyText sx={{ mt: 4.5 }}>
          ARE YOU READY TO FIND OUT?
        </AreYouReadyText>

        <RemainingCounter
          doneCount={totalRevealed}
          doneText="Revealed"
          remainingText="Unrevealed"
          totalCount={TOTAL_AMOUNT_NFTS}
        />

        <MetamaskButton {...ySpacing} />

        <InfoCTA
          onClick={() => setIsMoreInfoExpanded(!isMoreInfoExpanded)}
          data-test-id={TestIDs.RevealFunctionality.MoreInfoWrapper}
        >
          How does it work?
        </InfoCTA>
        <Grow
          unmountOnExit
          in={isMoreInfoExpanded}
          timeout={DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS}
        >
          <InfoContainer>
            <InfoText>
              Once you select and validate the Valentines to be revealed, they will enter a transitioning phase that will last approximately 30 minutes. At the end of the transition, you will see their true colors.
            </InfoText>
          </InfoContainer>
        </Grow>
      </ContentWrap>
    </Fade>
  )
}