import { StyledCheckbox, StyledFormControlLabel } from "./styles";
import { CheckArrowIcon } from "@global/assets";
import { FormControlLabelProps } from "@mui/material";

const Checkmark = () => <img src={CheckArrowIcon} alt="Check mark" />;
export const Checkbox: React.FC<Omit<FormControlLabelProps, 'control'>> = (props) =>
  <StyledFormControlLabel
    {...props}
    control={
      <StyledCheckbox
        icon={<></>}
        checkedIcon={<Checkmark />}
      />
    }
  />