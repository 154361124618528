import { DialogContent } from "@mui/material";
import { ConfirmationDialogProps } from "./types";
import {
  StyledDialog, StyledMainText,
  StyledSelectionButton, StyledSubText, StyledDialogActions
} from './styles';

export const RevealConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  setOpen,
  mainText,
  subText,
  confirmText,
  confirmAction,
  cancelText,
  nonTextContent
}) => {
  return (
    <StyledDialog open={open}>
      <DialogContent sx={{ display: 'flex', flexDirection: "column" }}>
        {
          mainText &&
          <StyledMainText>
            {mainText}
          </StyledMainText>
        }
        {
          subText &&
          <StyledSubText>
            {subText}
          </StyledSubText>
        }
        {
          nonTextContent
        }
      </DialogContent>

      <StyledDialogActions>
        {cancelText &&
          <StyledSelectionButton
            boldText
            onClick={() => setOpen(false)}
          >
            {cancelText}
          </StyledSelectionButton>
        }
        {confirmAction &&
          <StyledSelectionButton
            boldText
            onClick={confirmAction}
          >
            {confirmText}
          </StyledSelectionButton>}
      </StyledDialogActions>
    </StyledDialog>
  )
}
