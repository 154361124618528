import { Box, Fade, Typography } from "@mui/material";
import { HideIn, ViewHeader } from "@global/components";

import { DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS } from "@global/consts";
import { Web3Context } from "@component/web3-provider/context";
import { ValentineNFT } from "@component/valentine-nft/valentine-nft";
import { useContext, useEffect, useState } from "react";
import { ContentWrap, MyValentinesText, StyledRevealedText, ValentineStatsWrap, ValentinesWrap } from "./styles";
import { RevealConfirmationDialog, RevealedValentinesOverlay, RevealTimer, ValentinesSelector } from "@components";
import { Colors } from "@global/colors";
import { NftMetadata } from "@global/types";
import { setApprovalForAll, stakeTokens } from "./helpers/reveal-handler";
import { getStakedFromLocalStorage, removeStakedFromLocalStorage, updateStakedInLocalStorage } from "@global/utils";

export const ValentinesView = () => {
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const {
    account, walletStake, walletNFTs, totalNFTs,
    revealedTokenIDs, canWalletStake, updateWalletNFTs
  } = useContext(Web3Context);

  const [showRevealConfirmationDialog, setShowRevealConfirmationDialog] = useState(false);

  const [revealAt, setRevealAt] = useState<number>(0);

  const [valentinesToDisplay, setValentinesToDisplay] = useState<NftMetadata[]>([]);

  const [valentinesToReveal, setValentinesToReveal] = useState<number[]>([]);

  const [areNewValentinesRevealed, setAreNewValentinesRevealed] = useState<boolean>(false);

  const [isRevealOnTheWay, setIsRevealOnTheWay] = useState(false);
  const [areRevealTXsConfirmed, setAreRevealTXsConfirmed] = useState(false);

  const [successfulRevealTXs, setSuccessfulRevealTXs] = useState(0);


  const isNftSelected = (id: number) => selectedIds.indexOf(id) !== -1
  const isStaking = revealAt > 0;

  const onNftClick = (id: number) => {
    if (isStaking || revealedTokenIDs[id]) return;

    const isSelected = isNftSelected(id)
    if (isSelected) {
      setSelectedIds(prevState => prevState.filter(value => value !== id))
    } else {
      setSelectedIds([...selectedIds, id])
    }
  };

  const allAvailableNFTsIds = walletNFTs?.map((token) => token.id) || [];

  const handleSelectAll = () => setSelectedIds(allAvailableNFTsIds.filter((nftId) => !revealedTokenIDs[nftId]));
  const handleDeselectAll = () => setSelectedIds([]);

  const handleReveal = async () => {
    try {
      setAreNewValentinesRevealed(false);
      setShowRevealConfirmationDialog(false);
      setIsRevealOnTheWay(true);
      setAreRevealTXsConfirmed(false);

      await setApprovalForAll();
      setSuccessfulRevealTXs(1);

      const revealAt = await stakeTokens(selectedIds);
      setSuccessfulRevealTXs(2);

      setIsRevealOnTheWay(false);
      setAreRevealTXsConfirmed(true);
      setRevealAt(revealAt);
      setValentinesToDisplay(selectedIds.map((id) => ({ id })));
      setValentinesToReveal(selectedIds);
      setSelectedIds([])
      setSuccessfulRevealTXs(0);
      updateStakedInLocalStorage(selectedIds, revealAt);
    } catch (error) {
      console.error(error);
      setSuccessfulRevealTXs(-1);
    }
  };

  const handleFinishedTimer = async () => {
    setRevealAt(0);
    document.body.style.overflow = 'hidden';
    setAreNewValentinesRevealed(true);
    removeStakedFromLocalStorage();
    await updateWalletNFTs();
  }

  const headerText = isStaking ? "THE REVEAL IS ON..." : "THE CHOICE IS IN YOUR HANDS";

  const totalRevealed = totalNFTs > 0 ? Object.keys(revealedTokenIDs).length : 0;
  const totalUnrevealed = totalNFTs - totalRevealed;

  const subHeaderText =
    isStaking ?
      'You Will Soon Meet Your Valentines' :
      <>
        <HideIn component="span" view="down.sm">
          Select Valentines that you wanna reveal. But choose carefully.
          <br />
          You can reveal all at once or keep some unrevealed. You decide.
        </HideIn>
        <HideIn view="up.sm" component="span" >
          <Typography sx={{ whiteSpace: 'nowrap', fontSize: '14px' }}>
            Select the Valentines you want to reveal!
          </Typography>
        </HideIn>
      </>


  useEffect(() => {
    setSelectedIds([]);
  }, [account]);

  useEffect(() => {
    document.body.style.overflow = 'auto';
  }, []);

  useEffect(() => {
    setRevealAt(0);
    const stakedInfo = getStakedFromLocalStorage();

    if (stakedInfo !== null) {
      setValentinesToDisplay(stakedInfo.staked.map((id) => ({ id })));
      setRevealAt(stakedInfo.revealAt);
      setValentinesToReveal(stakedInfo.staked);

      return;
    }

    setValentinesToDisplay(walletNFTs ?? []);
  }, [walletNFTs]);

  useEffect(() => {
    const stakedInfo = getStakedFromLocalStorage();

    if (walletNFTs && walletStake && walletStake.revealAt !== stakedInfo?.revealAt) {
      const valentinesToDisplay = walletNFTs?.filter((token) => walletStake.staked[Number(token.id)])

      setValentinesToDisplay(valentinesToDisplay)
      setRevealAt(walletStake.revealAt);
    }
  }, [walletStake]);


  return (
    <Fade
      in
      unmountOnExit
      timeout={DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS * 3}
    >
      <Box>
        <RevealConfirmationDialog
          open={showRevealConfirmationDialog}
          setOpen={setShowRevealConfirmationDialog}
          confirmAction={handleReveal}
          mainText={
            <>ARE YOU SURE YOU WANT TO REVEAL
              <span style={{ color: Colors.SassyPink }}>
                {' '} {selectedIds.length}
              </span> {`VALENTINE${selectedIds.length === 1 ? '' : 'S'}`}
            </>
          }
          confirmText="YES, START REVEALING"
          cancelText="NO, LET ME GO BACK"
        />

        <Fade
          unmountOnExit
          in={successfulRevealTXs !== -1 && areNewValentinesRevealed}
          timeout={DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS * 1.5}
        >
          <Box>
            <RevealedValentinesOverlay revealedIDs={valentinesToReveal} osAccount={account!} />
          </Box>
        </Fade>

        <Fade in={successfulRevealTXs === -1 || (isRevealOnTheWay && !areRevealTXsConfirmed)} unmountOnExit>
          <Box>
            <RevealConfirmationDialog
              open
              setOpen={setShowRevealConfirmationDialog}
              mainText={
                successfulRevealTXs === -1 ?
                  "Oh no! Something went wrong." :
                  <>You are {2 - successfulRevealTXs} <span style={{ color: Colors.HomaGreenLight }}>successful</span> {`transaction${2 - successfulRevealTXs === 1 ? '' : 's'}`} away from seeing your
                    <span style={{ color: Colors.SassyPink }}>
                      {' '} {selectedIds.length}
                    </span> {`VALENTINE${selectedIds.length > 1 ? 'S' : ''}`}
                  </>
              }
              subText={successfulRevealTXs === -1 ? 'Please refresh the page and try again.' : successfulRevealTXs === 0 ? `Please be patient with the chain..` : 1 ? 'Hang tight!' : 2 ? 'Both transactions are confirmed' : ''}
            />
          </Box>
        </Fade>


        <ContentWrap>
          <ViewHeader
            headerText={headerText}
            subHeaderText={subHeaderText}
          />

          {totalNFTs > 0 &&
            <>
              {
                !isStaking &&
                <ValentineStatsWrap>
                  <MyValentinesText>
                    My valentines ({totalNFTs})
                  </MyValentinesText>
                  <Box sx={{ height: '100%' }}>
                    <StyledRevealedText>
                      Revealed <span>{totalRevealed}</span>
                    </StyledRevealedText>
                    <StyledRevealedText sx={{ ml: 2 }}>
                      Unrevealed <span>{totalUnrevealed}</span>
                    </StyledRevealedText>
                  </Box>
                </ValentineStatsWrap>
              }
              {
                isStaking ?
                  <RevealTimer
                    revealAt={revealAt}
                    onFinished={handleFinishedTimer}
                  /> :
                  <ValentinesSelector
                    onSelectAll={handleSelectAll}
                    onDeselectAll={handleDeselectAll}
                    canReveal={canWalletStake && selectedIds.length > 0}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                    allAvailableNFTsIds={totalNFTs}
                    totalRevealed={totalRevealed}
                    totalUnrevealed={totalUnrevealed}
                    onConfirmReveal={() => setShowRevealConfirmationDialog(true)}
                  />
              }

              <ValentinesWrap smallNumbers={valentinesToDisplay.length < 3}>
                {valentinesToDisplay.map((nft: NftMetadata) => {
                  const isTokenRevealed = revealedTokenIDs[nft.id];
                  const marginRight = valentinesToDisplay.length < 3 ? 6 : 0;

                  return (
                    <ValentineNFT
                      sx={{ mb: 6, mr: { xs: 0, sm: marginRight, md: marginRight, lg: marginRight, xl: marginRight } }}
                      key={nft.id}
                      // @ts-ignore
                      id={nft.id}
                      isRevealed={isTokenRevealed}
                      isStaking={isStaking}
                      allowSelecting={!isStaking && !isTokenRevealed}
                      imageSrc={nft?.imageSrc}
                      isSelected={isNftSelected(nft.id)}
                      onClick={() => onNftClick(nft.id)}
                    />
                  );
                })}
              </ValentinesWrap>
            </>
          }
        </ContentWrap>
      </Box >
    </Fade >
  )
}