import { Colors, fadedWhite06, pinkToBlue } from "@global/colors";
import { Button } from "@global/components";
import { alpha, Box, styled, Typography } from "@mui/material";


export const InfoText = styled(Typography)(({ theme }) => ({
  color: fadedWhite06,
  textAlign: 'center',
  lineHeight: theme.spacing(3),

  [theme.breakpoints.down('sm')]: {
    textAlign: 'left',
    fontSize: theme.typography.pxToRem(14),

    '.space': {
      display: 'none'
    }
  }
}))

export const SuccessText = styled(Typography)({
  textAlign: 'center',
  color: alpha(Colors.HomaGreen, 0.7),
})

export const InfoCTA = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color: Colors.SassyPink,
  textDecoration: 'underline',
  marginBottom: theme.spacing(1),
  transition: 'color 0.3s ease-out',

  '&:hover': {
    cursor: 'pointer',
    color: Colors.ShyPink,
  }
}));

export const InfoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',

  padding: theme.spacing(2),
  background: pinkToBlue(0.1),
  maxWidth: '90vw',

  'p': {
    lineHeight: theme.spacing(4),
  },

  [theme.breakpoints.up('md')]: {
    maxWidth: '650px',
  }
}))

export const GoBackButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),

  '.MuiTypography-root': {
    fontWeight: theme.typography.fontWeightExtraBold
  }
}));

export const PublicMintDate = styled('span')({
  color: Colors.SassyPink,
});

export const BoldText = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.pxToRem(24),
  textAlign: 'center',
}))