import { Box, Fade } from "@mui/material";
import { Button, ViewHeader } from "@global/components";
import { ContentWrap } from "./styles";
import { DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS } from "@global/consts";

export const NoValentinesView = () => (
  <Fade in unmountOnExit timeout={DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS}>
    <ContentWrap>
      <ViewHeader
        headerText="Error...No Valentines Detected For Reveal"
        subHeaderText={
          <Box component="span" sx={{ display: 'block', mt: 4, mb: 6 }}>
            Head to Opensea to get one before entering the reveal. HOMA World awaits your arrival.
          </Box>
        }
      />

      <Button
        boldText
        sx={{ mb: 4 }}
        size="large"
        onClick={() => window.open('https://opensea.io/collection/homa-gang-valentine', '_blank')}
      >
        GO TO OPENSEA
      </Button>
    </ContentWrap>
  </Fade>
)