import { hover as hoverAnimation, expandAndContract as expandAndContractAnimation } from '@global/animations';
import { Colors, fadedWhite06 } from '@global/colors';
import { GradientText } from '@global/components';
import { DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS } from '@global/consts';
import { styled, Box, Typography } from '@mui/material';
import { transientProps } from '@global/utils';

export const StyledWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center'
});

export const StyledGradientText = styled(GradientText)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(64),
  fontWeight: theme.typography.fontWeightExtraBold,

  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(48),
  }
}))


export const StyledCountText = styled(Typography, transientProps('hover', 'jump'))<{ hover?: boolean, jump?: boolean }>(({ theme, hover, jump }) => ({
  color: Colors.White,
  fontSize: theme.typography.pxToRem(64),
  fontWeight: theme.typography.fontWeightBold,
  animation: (!hover && !jump) ? 'none' : `${hover ? hoverAnimation : expandAndContractAnimation(0.05)} ${DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS}ms linear`,

  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(48),
  }
}));

export const StyledExplainerText = styled(Typography)(({ theme }) => ({
  color: fadedWhite06,
  textTransform: 'uppercase',
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: theme.typography.pxToRem(14),

  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(12),
  }
}));
