import { STAKED_VALENTINES_KEY } from "@component/web3-provider/consts";
import { Maybe, StakedInfo } from "@global/types";
import { Web3 } from "./web3";

export const getStakedFromLocalStorage = (): Maybe<StakedInfo> => {
  const stakedInfoJSON = localStorage.getItem(STAKED_VALENTINES_KEY);

  if (stakedInfoJSON !== null) {
    const stakedMap = JSON.parse(stakedInfoJSON);
    const walletStake = stakedMap[Web3.account.toLowerCase()];

    if (Object.keys(stakedMap).length === 0 || walletStake === undefined) {
      return null;
    }

    const [staked, revealAt] = walletStake.split('.');

    return { staked: staked.split(',').map(Number), revealAt: Number(revealAt) };
  }
  return null;
}